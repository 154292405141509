<template>
    <Toast />
    <div class="card" :v-show="!completed">
        <Toolbar class="p-fluid grid header-title text-bold">
            <template #start>
                <div class="text-bold">{{ modelSchema.Model }}</div>
            </template>

            <template #end>
                <Button label="Submit Model" @click="processModel" icon="pi pi-upload" class="p-button-success" />
            </template>
        </Toolbar>
        <div v-show="dataProcessing">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin: 0.5rem"></i>
            <label> Uploading Dataset</label>
        </div>
        <div class="grid text-bold head-space">
            <div class="field col-5">
            <section class="field">
                <label>Cost Unit</label>
                <InputText id="costUnit" v-model="modelSchema.CostUnit" type="text" placeholder="Kg" />
            </section>
            <section class="field">
                <label>Model Description</label>
                <Textarea id="description" rows="4" v-model="modelSchema.ModelDescription" style="resize:none" />
            </section>
        </div>

            <div class="field col-7">
                <label>Detailed Description</label>

                <Textarea id="description" rows="9" v-model="modelSchema.DetailedDescription" style="resize:vertical" />
        </div>
        </div>
       
        <div class="grid">
            <div class="col-12 md-12 header-title text-bold">Input Attributes</div>
            <div class="col-12 md-12 subheader-title">
                <div class="col-1 md-2">
                    <label>Name</label>
                </div>
                <div class="col-3 md-3">
                    <label>Alternate Name</label>
                </div>
                <div class="col-1 md-1">
                    <label>Max</label>
                </div>
                <div class="col-1 md-1">
                    <label>Min</label>
                </div>
                <div class="col-5 md-5">
                    <label>Description</label>
                </div>
                <div class="col-1">
                    <label>Cost</label>
                </div>
            </div>

            <div v-for="attribute in inputAttributes" :key="attribute" class="col-12 grid table">
                <div class="col-1 md-2">
                    <InputText v-model="attribute.Name" />
                </div>
                <div class="col-3 md-3">
                    <InputText v-model="attribute.AlternateName" />
                </div>
                <div class="col-1 md-1">
                    <InputText v-model="attribute.UpperLimit" />
                </div>
                <div class="col-1 md-1">
                    <InputText v-model="attribute.LowerLimit" />
                </div>
                <div class="col-5 md-5">
                    <InputText v-model="attribute.Description" />
                </div>
                <div class="col-1 md-1">
                    <InputText v-model="attribute.Cost" />
                </div>
            </div>

            <div class="col-12 p-md-12 header-title text-bold">Output Attributes</div>
            <div class="col-12 md-12 subheader-title">
                <div class="col-3 md-3">
                    <label>Name</label>
                </div>
                <div class="col-3 md-3">
                    <label>Alternate Name</label>
                </div>
                <div class="col-1 md-1">
                    <label>Unit</label>
                </div>
                <div class="col-5 md-5">
                    <label>Description</label>
                </div>
            </div>

            <div v-for="attribute in outputAttributes" :key="attribute" class="col-12 md-12 grid">
                <div class="p-field col-3 p-md-3">
                    <InputText v-model="attribute.Name" />
                </div>
                <div class="p-field col-3 p-md-3">
                    <InputText v-model="attribute.AlternateName" />
                </div>
                <div class="p-field col-1 p-md-1">
                    <InputText v-model="attribute.Unit" />
                </div>

                <div class="p-field col-5 p-md-5">
                    <InputText v-model="attribute.Description" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import AdminService from '../tipureServices/AdminService';

export default {
    props: {
        targetAccount: {
            type: String,
            default() {
                return null;
            },
        },
        model: {
            type: Object,
            default() {
                return null;
            },
        },
    },

    data() {
        return { dataProcessing: false };
    },
    adminService: null,
    created() {
        this.adminService = new AdminService();
    },
    methods: {
        processModel() {
            this.dataProcessing = true;

            const account = ref(this.targetAccount);
            const modelSchema = ref(this.model);
            let newModel = {
                Default: false,
                Display: false,
                Description: modelSchema.value.ModelDescription,
                DetailDescription: modelSchema.value.DetailedDescription,
                Name: modelSchema.value.Model,
                DisplayName: modelSchema.value.Model,
                account: account.value,
                schema: modelSchema.value,
            };
// console.log(newModel+" account"+" modelSchema");
            this.adminService.submitModel(newModel).then((response) => {
                if (response) {
                    alert('Model Uploaded');
                    this.dataProcessing = false;
                    this.$router.push('/admin/modelManagement');
                    //completed = true;
                this.$toast.add({ severity: 'success', summary: 'Update Complete', detail: 'Model processing.', life: 3000 });
                    }
            }).catch(err=>{
                this.$toast.add({ severity: 'error', summary: 'Update Failed', detail: err, life: 3000 });
                this.dataProcessing = false;
                this.$router.push('/admin/newModel');
            });
        },
    },
    setup(props) {
        const account = ref(props.targetAccount);
        const modelSchema = ref(props.model);
        //const adminService = new AdminService();
        let completed = false;
        let processing = false;
        const inputAttributes = computed(() => {
            return modelSchema.value.InputAttributes;
        });

        const outputAttributes = computed(() => {
            return modelSchema.value.OutputAttributes;
        });

        const submitModel = () => {
            /*let newModel = {
                Default: false,
                Display: false,
                Description: modelSchema.value.ModelDescription,
                Name: modelSchema.value.Model,
                DisplayName: modelSchema.value.Model,
                account: account.value,
                schema: modelSchema.value,
            };
*/

            processing = true;
            //adminService.submitModel(newModel).then((response) => {
            //    if (response) {
            //        alert('Model Uploaded');
            //        completed = true;
            //        if (processing) processing = false;
            //    }
            // this.$toast.add({ severity: 'success', summary: 'Update Complete', detail: 'Model processing.', life: 3000 });
            //});
        };

        watch(
            () => props.modelSchema,
            (first, second) => {
                console.log('Schema Changed', first, second);
            },
            () => processing,
            (first, second) => {
                // console.log(processing);
                console.log('Status Changed', first, second);
            },
            () => props.targetAccount,
            (first, second) => {
                console.log('Schema Changed', first, second);
            }
        );
        return { modelSchema, account, inputAttributes, outputAttributes, submitModel, completed, processing };
    },
};
</script>
   
<style lang="scss" scoped>
@import '../../public/assets/theme/theme-cyan.scss';
@import '../../public/assets/sass/theme/_variables.scss';
.attribute-grid {
    .header-title {
        background-color: darkslategrey;
        color: white;
    }

    .header-lable {
        background-color: darkcyan;
        color: white;
    }
}
.p-inputtext {
    width: 100%;
}
.header-title {
    background-color: $primaryDarkerColor;
    color: $primaryTextColor;
    font-weight: $tableHeaderFontWeight;
}
.subheader-title {
    background-color: $infoButtonBg;
    color: $primaryTextColor;
    font-weight: $tableHeaderFontWeight;
    display: flex;
    padding: 0rem;
}
.p-toolbar {
    padding: 0.5rem;
}
.text-bold {
    font-weight: $tableHeaderFontWeight;
}
.head-space {
    margin-top: 0.5rem;
}
</style>
