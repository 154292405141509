<template>
    <div>
        <div class="card">
            <h4>New Model</h4>
            <!-- <div class="field " style="float:right">
            <router-link v-if="accounts" :to="{ name: 'listUser', params: {} }">
                <Button label="User Management" icon="pi pi-plus" class="button-sm Adddomain" />
            </router-link>
            <router-link v-if="accounts" :to="{ name: 'addModel', params: {} }">
                <Button label="Domain Management" icon="pi pi-plus" class="button-sm Adddomain" />
            </router-link>
        </div> -->
            <div class="formgroup-inline text-bold">
                <div class="field">
                    <Button label="Sample Template" @click.prevent="
                        this.loading = true;
                    downloadItem({
                        url: 'https://ccuse2tipurestorage.blob.core.windows.net/public/sample_data_template_v1.csv',
                        label: 'sample_template_file.csv',
                    });
                    " icon="pi pi-save" class="p-button-success" />
                </div>
            </div>
            <div class="formgroup-inline text-bold">
                <div class="field">
                    <label for="modelName" class="sr-only">Model Name</label>
                    <InputText id="modelName" v-model="modelName" type="text" placeholder="model" />
                </div>

                <div v-if="accounts" class="field">
                    <label for="accountName" class="sr-only">Account</label>
                    <Dropdown v-model="targetAccount" id="accountName" :options="accounts" optionLabel="name"
                        placeholder="Select account" />
                </div>
                <div class="field" id="fileUpload">
                    <FileUpload name="file[]" mode="basic" :customUpload="true" @uploader="modelUpload" />
                </div>
            </div>
            <!-- <section v-if="targetAccount && modelName">
            <p>{{Combinedcustomers[targetAccount.name]}} and {{modelName}}</p>
            <h5 v-if="Combinedcustomers[targetAccount.name].includes(modelName)">
                <p>**The Name {{modelName}} already exists under {{targetAccount.name}}, please try other names**</p>
            </h5>  
            </section> -->

            <div class="" style="align: ">
                <ul>
                    <li>Use only alphanumeric characters for model name</li>
                    <li>Do not use hypen ('-') and underscore ('_') characters in model name</li>
                    <li>Chose names no more than 15 characters for model name</li>
                    <li>Template file should be saved as CSV UTF-8 file</li>
                    <li>The data files uploaded by you will be deleted once the machine learning models gets created and
                        deployed</li>
                </ul>
            </div>

        </div>
    </div>
    <div id="checkBasic" v-if="Domvar" v-html="Domvar"></div>
    <formulation v-if="modelPreview" />
    <schema-editor v-if="editSchema" :model="modelSchema" :targetAccount="targetAccount['domain']" />
    <Toast />
    <div v-if="loading" style="margin-top:2em;width:auto">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.7em; float: left;"></i>
        <p id="loadng"><i>{{ loading_value }}</i></p>
    </div>
</template>
   
<script>
import formulation from '../tipure/formulation.vue';
import schemaEditor from './schemaEditor.vue';
import AdminService from '../tipureServices/AdminService';
import axios from 'axios';

export default {
    data() {
        return {
            modelPreview: false,
            modelName: '',
            editSchema: false,
            modelSchema: null,
            targetAccount: null,
            accounts: null,
            account_name: null,
            loading: false,
            loading_value: null,
            processing: true,
            Combinedcustomers: null,
            Domvar: null
        };
    },
    setup() {
        const editMode = false;

        return { editMode };
    },

    adminService: null,
    async created() {
        this.targetAccount = null
        this.loading = true;
        this.loading_value = "Loading models,please wait...";
        this.adminService = new AdminService();
        this.account_name = this.adminService.get_indi_account()
        // this.targetAccount=this.account_name.replace(/[.]/g,'')
        if (this.account_name["domain"] === "chemourscom") {
            await this.adminService.getCustomerDomainList().then((data) => {
                this.accounts = data.data;
                this.loading = false;
                this.loading_value = null;
            }).catch(err => {
                this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                this.Domvar = null;
                this.loading = false;
                this.loading_value = null;
                // console.log(err);
            });
        } else {
            this.targetAccount = this.account_name
            this.loading = false;
            this.loading_value = null;
        }
        this.adminService.getCombinedcustomers().then((data) => {
            this.Combinedcustomers = data;
        }).catch(err => {
            this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
            this.Domvar = null;
            this.loading = false;
            this.loading_value = null;
            // console.log(err);
        });

    },
    mounted() { },
    methods: {
        CheckEmptyFields() {
            this.Domvar = null
            if (this.targetAccount || this.modelName) {
                if (!this.modelName && this.targetAccount.name) {
                    this.Domvar = "**Model Name field is Blank, please provide the Model Name**";
                    return true
                }
                else if (!this.targetAccount && this.modelName) {
                    this.Domvar = "**Account field is Blank, please provide the Account Name**";
                    return true
                }
                else {
                    this.Domvar = null
                    return false
                }
            }
            else {
                this.Domvar = "**Input fields are Blank, please provide the proper values**";
                return true
            }
        },
        nameExists() {
            return this.adminService.check_nameExists(this.modelName, this.targetAccount['domain']).then((data) => {
                return data.data;

            }).catch(err => {
                this.Domvar = null;
                this.loading = false;
                this.loading_value = null;
                this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
            });
        },
        async downloadItem({ url, label }) {
            this.loading_value = "downloading Sample Template File..."
            const response = await axios.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            this.loading = false;
            this.loading_value = null
            URL.revokeObjectURL(link.href);
        },
        async modelUpload(event) {
            this.editSchema = false;
            this.loading = true;
            this.loading_value = "Validating uploaded file '" + event.files[0].name + "', please wait..."
            if (!this.CheckEmptyFields()) {
                let dataname = await this.nameExists()
                if (dataname) {
                    this.Domvar = dataname;
                    this.loading_value = null
                    this.loading = false;
                    // console.log(dataname);
                    return null
                }
                else {
                    // console.log(dataname);
                    let tmp_filetype = event.files[0].name.split(".");
                    if ((event.files[0].name.split(".")[(tmp_filetype.length) - 1]).toLowerCase() == 'csv') {
                        this.Domvar = '';
                        this.adminService.checkModel(this.modelName, this.targetAccount['domain'], event.files[0]).then((data) => {
                            if (data.code === 406) {
                                this.loading = false;
                                this.loading_value = null;
                                this.Domvar = `<table><tr><td><b>Error-code: </b></td><td>406</td></tr></table>`
                                for (let Errors in data.message) {
                                    let msg = Object.keys(data.message[Errors].data.data)
                                    this.Domvar += `<table><tr><td><b>Message: </b></td><td>${data.message[Errors].data.message}</td></tr>\
                    <tr><td><b>Column: </b></td> &nbsp;<td><b>Row: </b></td></tr>`
                                    for (let i in msg) {
                                        this.Domvar += `
                        <tr><td>${msg[i]}</td><td>${data.message[Errors].data.data[msg[i]].toString()}</td></tr>`
                                    }
                                    this.Domvar += `</table><hr>`
                                }

                            }
                            else if ((data.code === 201) && (data.message).toLowerCase() === "success") {
                                // this.$toast.add({ severity: 'success', summary: "Validation Success", detail: "No erros found in pre-checks", life: 3000 });
                                this.$toast.add({ severity: 'success', summary: "Validation Success", life: 3000 });
                                this.loading_value = "Uploading file '" + event.files[0].name + "', please wait..."
                                this.adminService.uploadModel(this.modelName, this.targetAccount['domain'], event.files[0]).then((data) => {
                                    this.Domvar = null
                                    this.modelSchema = data.message;
                                    this.editSchema = !this.editSchema;
                                    this.loading = false;
                                    this.loading_value = null;
                                }).catch(err => {
                                    this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                                    this.Domvar = null;
                                    this.loading = false;
                                    this.loading_value = null;
                                    // console.log(err);
                                });
                            }
                            else {
                                // console.log(data);
                                // console.log(data.code);
                                this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: data.message, life: 5000 });
                                this.Domvar = null;
                                this.loading = false;
                                this.loading_value = null;
                            }
                        }).catch(err => {
                            this.Domvar = null;
                            this.loading = false;
                            this.loading_value = null;
                            this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                        });
                    }
                    else {
                        this.Domvar = null
                        this.loading = false;
                        this.loading_value = null
                        this.Domvar = `<p>**The entered File '` + event.files[0].name + `' is not of type 'csv', please upload a valid 'csv' file!**</p>`
                    }
                }
            }
            else {
                this.loading_value = null
                this.loading = false;
            }
        },

        submitModel() {
            alert(this.modelSchema);
        },
    },

    inject: ['SharedState'],

    components: { formulation, schemaEditor },
};
</script>

<style lang="scss" scoped>
@import '../../public/assets/theme/theme-cyan.scss';
@import '../../public/assets/sass/theme/_variables.scss';

#fileUpload {
    padding-top: 0.5rem;
}

.text-bold {
    font-weight: $tableHeaderFontWeight;
}

h5 {
    color: rgb(207, 7, 7);
    font-size: small;
}

#checkBasic {
    padding: 0.2em;
    text-align: left;
    margin: 1em;
    color: rgb(185, 0, 0);
    font-size: small;
    font-style: italic;
}

.Adddomain {
    margin: 2px;
    padding: 0.6em;
    border: none;
    background-color: rgb(178, 4, 4);
    float: right;
    position: relative;
}

#loadng {
    padding: 3px;
    margin-left: 2em;
    color: rgb(173, 173, 173);
    font-size: small;
    font-style: italic;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
</style>